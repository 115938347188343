<template>
    <div>
         <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Bienvenido</h6>
          </b-dropdown-header>
          <b-dropdown-item v-for="(link, index) in links" :key="index" @click="redirect(link.link)">
            <i :class="link.icon"></i>
            <span>{{ link.name }}</span>
          </b-dropdown-item>
    </div>
</template>
<script>
export default {
  data() {
    return {
        links: [
            { link: 'Perfil', name: 'Perfil', icon: 'ni ni-single-02'},
            { link: 'Configuracion', name: 'Configuración', icon: 'ni ni-settings-gear-65'},
            { link: 'Ayuda', name: 'Soporte', icon: 'ni ni-support-16'},
            { link: 'Login', name: 'Logout', icon: 'ni ni-user-run'},
        ]
    }
  },
  methods: {
    redirectSupport () {
      this.$router.push({ name: 'Ayuda' });
    },
    redirectPerfil () {
      this.$router.push({ name: 'Perfil' });
    },

    redirectConfiguracion () {
      this.$router.push({ name: 'Configuracion' });
    },
    redirect (url) {
      this.$router.push({ name: url });
    }
  },
};
</script>
