<template>
  <div>
    <router-view v-if="isLogged"></router-view>
    <login v-if="!isLogged && !register" @logueo="realizaLogin" @registro="realizaRegistro" />
    <register v-if="register" @registro="realizaRegistro" />
  </div>
</template>

<script>
  import Login from '@/views/Pages/Login.vue';
  import Register from '@/views/Pages/Register.vue';

  export default {
    data() {
      return {
        isLogged: false,
        register: false
      }
    },
    components: {
      Login,
      Register
    },

    mounted () {
      if (window.location.pathname === '/register') { this.register = true }
    },
    methods: {
      realizaLogin () {
        this.isLogged = true
        this.$router.push({ name: 'Dashboard' });
      },

      realizaRegistro () {
        if (this.register) {
          this.register = false
          this.$router.push({ name: 'Login' });
        } else {
          this.register = true
          this.$router.push({ name: 'Register' });
        }
        // this.$router.push({ name: 'Dashboard' });
      }
    }
}
</script>
