const api = {
    // LOGIN
    async login (details) {
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&')
        // https://8hfamctqsk.execute-api.eu-central-1.amazonaws.com/dev 
        const resultado = await fetch('https://8hfamctqsk.execute-api.eu-central-1.amazonaws.com/dev/store/signin', {
            // const resultado = await fetch('https://dev.security.dotiplatform.com/store/signin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Doti df90a1d1-8ca5-4105-ac40-773ef66ca84a' },
            body: formBody,
        })
        .then((res) => res.json())
        .then((res) => { return res })
        .catch((err) => { return err })
        
        return resultado
    },

    // LOGIN
    async register (details) {
        // https://eichzwrco6.execute-api.eu-central-1.amazonaws.com/dev
        const resultado = await fetch('https://eichzwrco6.execute-api.eu-central-1.amazonaws.com/dev/user/register', {
            // const resultado = await fetch('https://dev.registration.dotiplatform.com/user/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Doti df90a1d1-8ca5-4105-ac40-773ef66ca84a' },
            body: JSON.stringify(details),
        })
        .then((res) => res.json())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado
    },

    async setTicket (details, user) {
        const auto = 'Bearer ' + user.token
        // https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
        const resultado = await fetch('https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev/sales', {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json', 'Authorization': 'Doti df90a1d1-8ca5-4105-ac40-773ef66ca84a' },
            headers: { 'Content-Type': 'application/json', 'Authorization': auto },
            body: JSON.stringify(details),
        })
        .then((res) => res.json())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado
    },

    async getNumeroFactura (user) {
        const auto = 'Bearer ' + user.token
        // https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
        // https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev
        const resultado = await fetch('https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev/sales/number', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': auto }
            // body: JSON.stringify(details),
        })
        .then((res) => res.text())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado

    },

    async getTickets (user, desde, hasta) {
        const auto = 'Bearer ' + user.token
        // https://6dffzfmwcj.execute-api.eu-central-1.amazonaws.com/dev
        // https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
        const date = new Date()
        let fecha
        if (desde === undefined) {
            const mes = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
            const dia = date.getUTCDate() < 10 ? '0' + (date.getUTCDate()) : date.getUTCDate()
            fecha = date.getFullYear() + '-' + mes + '-' + dia
        }
        const resultado = await fetch(desde !== undefined ? 'https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev/sales?startDate=' + desde + '&endDate='+ hasta : 'https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev/sales?startDate=' + fecha, {
            // const resultado = await fetch('https://dev.sales.dotiplatform.com/sales?startDate=2022-05-19', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': auto }
            // body: JSON.stringify(details),
        })
        .then((res) => res.json())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado

    },

    // GASTOS
    async getExpenses (user, desde, hasta) {
        const auto = 'Bearer ' + user.token
        // https://avy6dqn143.execute-api.eu-central-1.amazonaws.com/dev
        // https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev
        const date = new Date()
        let fecha
        if (desde === undefined) {
            const mes = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
            const dia = date.getUTCDate() < 10 ? '0' + (date.getUTCDate()) : date.getUTCDate()
            fecha = date.getFullYear() + '-' + mes + '-' + dia
        }
        const resultado = await fetch(desde === undefined ? 'https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev/expense?startDate='+ fecha : 'https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev/expense?startDate=' + desde + '&endDate=' + hasta, {
            // const resultado = await fetch('https://dev.expense.dotiplatform.com/expense?startDate=2022-05-19', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': auto }
            // body: JSON.stringify(details),
        })
        .then((res) => res.json())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado

    },

    async downloadFactura (user, id) {
        const auto = 'Bearer ' + user.token
        // https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
        // https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev
        const resultado = await fetch('https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev/expense/' + id, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': auto }
            // body: JSON.stringify(details),
        })
        .then((res) => res.text())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado

    },

    async deleteFactura (user, id) {
        const auto = 'Bearer ' + user.token
        // https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
        // https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev
        const resultado = await fetch('https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev/expense/' + id, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': auto }
            // body: JSON.stringify(details),
        })
        .then((res) => res.text())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado

    },

    async setExpenses (user, details) {
        // console.log('Llegooo?', details)
        // const auto = 'Bearer ' + user.token
        // const resultado = await fetch('https://dev.expense.dotiplatform.com/expense', {
        //     method: 'POST',
        //     headers: {  'Content-Type': 'multipart/form-data', 'Authorization': auto }
        // })
        // .then((res) => res.json())
        // .then((res) => { return res })
        // .catch((err) => { return err });
        // console.log(resultado)
        // return resultado
        // https://avy6dqn143.execute-api.eu-central-1.amazonaws.com/dev
        // https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev
        const auto = 'Bearer ' + user.token
        const resultado = await fetch('https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev/expense', {
            // const resultado = await fetch('https://avy6dqn143.execute-api.eu-central-1.amazonaws.com/dev/expense', {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json', 'Authorization': 'Doti df90a1d1-8ca5-4105-ac40-773ef66ca84a' },
            headers: { 'Content-Type': 'application/json', 'Authorization': auto },
            body: JSON.stringify(details),
        })
        .then((res) => res.json())
        .then((res) => { return res })
        .catch((err) => { return err });
        
        return resultado
    },

    test () {
        // console.log(this.mapGetters.authUser)
    }
}

export default api