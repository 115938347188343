<!--CABECERA SUPERIOR-->
<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
  >
    <b-navbar-nav class="header-component align-items-center ml-md-auto">
      <li class="nav-item prueba d-xl-none">
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            'active': $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <span class="menu-toggle"></span>
          </div>
        </div>
      </li>
      <li class="mr-4">
        <router-link to="/scanBono">
          <img alt="Image placeholder" width="30" href="/scanBono" src="../../../public/qr-code-scan.svg" />
        </router-link>
      </li>
      <li class="mr-4">
        <administrar-usuarios />
      </li>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0 accound_wrapper">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <span class="avatar">
              <img alt="Image placeholder" src="../../../public/account.svg" />
            </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ usuario }}</span>
            </b-media-body>
          </b-media>
        </a>

        <template>
          <opciones-perfil />
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import AdministrarUsuarios from '@/components/Menu/AdministrarUsuarios';
import OpcionesPerfil from '@/components/Menu/OpcionesPerfil';
import { BaseNav } from '@/components';

export default {
  components: {
    BaseNav,
    AdministrarUsuarios,
    OpcionesPerfil
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description:
        'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      usuario: null,
    };
  },
  mounted() {
    this.usuario = localStorage.getItem('usuario') || '[]';
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  },
};
</script>
