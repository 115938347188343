import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: { 
        appName: 'Test',
        facturacion: false,
        promociones: false,
        gasto: false,
        encuestas: false,
        gestoria: false,
        dashboard: false,
        authUser: null,
        controlUsuarios: false
    },

    getters: {
        appName: state => state.appName,
        authUser: state => state.authUser,
        facturacion: state => state.facturacion,
        promociones: state => state.promociones,
        gasto: state => state.gasto,
        encuestas: state => state.encuestas,
        gestoria: state => state.gestoria,
        dashboard: state => state.dashboard,
        controlUsuarios: state => state.controlUsuarios
    },

    mutations: {
        setAppName (state, appName) {
            state.appName = appName
        },

        setAuthUser (state, authUser) {
            state.authUser = authUser
        },

        setFacturacion (state, facturacion) {
            state.facturacion = facturacion
        },

        setPromociones (state, promociones) {
            state.promociones = promociones
        },

        setGasto (state, gasto) {
            state.gasto = gasto
        },

        setEncuestas (state, encuestas) {
            state.encuestas = encuestas
        },

        setDashboard (state, dashboard) {
            state.dashboard = dashboard
        },
        
        setGestoria (state, gestoria) {
            state.gestoria = gestoria
        },

        setControlUsuarios (state, controlUsuarios) {
            state.controlUsuarios = controlUsuarios
        }
    },

    actions: {
        setAppName ({ commit }, appName) {
            commit('setAppName', appName)
        },

        setAuthUser ({ commit }, authUser) {
            commit('setAuthUser', authUser)
        },

        setFacturacion ({ commit }, facturacion) {
            commit('setFacturacion', facturacion)
        },

        setPromociones ({ commit }, promociones) {
            commit('setPromociones', promociones)
        },

        setGasto ({ commit }, gasto) {
            commit('setGasto', gasto)
        },

        setEncuestas ({ commit }, encuestas) {
            commit('setEncuestas', encuestas)
        },

        setDashboard ({ commit }, dashboard) {
            commit('setDashboard', dashboard)
        },

        setGestoria ({ commit }, gestoria) {
            commit('setGestoria', gestoria)
        },

        setControlUsuarios ({ commit }, controlUsuarios) {
            commit('setControlUsuarios', controlUsuarios)
        }
    },
    strict: true
})