<template>
  <div class="form-body custom-login container-fluid p-0">
    <b-alert v-if="showError" show variant="danger" id="alertError"><a href="#" class="alert-link">{{ msgError }}</a></b-alert>
    <b-alert v-if="showSuccess" show variant="success"><a href="#" class="alert-link">Registro realizado de forma correcta</a></b-alert>
    <div class="row">
      <div class="col-md-6 col-sm-12 p-0 user-decoration">
        <h1>
          <a href=""><span class="logo"></span></a>
        </h1>
        <img src="../../../public/mobile-ticket.png" alt="" />
      </div>
      <div class="col-xl-6 col-lg-12 p-0 user-login">
        <div class="form-content">
          <div class="form-items">
            <div class="">
              <a href=""><span class="logo"></span></a>
              <h3>Conoce a tus cliente de manera personalizada.</h3>
              <h4>
                Accede a la plataforma que te ayuda analizar los habitos de
                compra de tu cliente.
              </h4>
            </div>
            <div>
              <form>
                <b-row>
                  <b-col md="6">
                    <label for="nombre" class="mb5x">Nombre*</label>
                    <input v-model="nombre" id="user" type="text" name="nombre" required />
                  </b-col>
                  <b-col md="6">
                    <label for="apellidos" class="mb5x">Apellidos*</label>
                    <input v-model="apellidos" type="text" name="apellidos" required />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <label for="empresa" class="mb5x">Empresa*</label>
                    <input v-model="empresa" id="user" type="text" name="empresa" required />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <label for="tel" class="mb5x">Teléfono*</label>
                    <input v-model="tel" id="user" type="text" name="tel" required />
                  </b-col>
                  <b-col md="6">
                    <label for="mail" class="mb5x">Email*</label>
                    <input v-model="mail" type="text" name="mail" required />
                  </b-col>
                </b-row>
                 <b-row>
                  <b-col md="6">
                    <label for="pass" class="mb5x">Contraseña*</label>
                    <input v-model="pass" id="user" type="password" name="pass" required />
                  </b-col>
                  <b-col md="6">
                    <label for="pass2" class="mb5x">Repetir contraseña*</label>
                    <input v-model="pass2" type="password" name="pass2" required />
                  </b-col>
                </b-row>
              </form>
            </div>
            <div class="w-100 mt-3">
              <div class="d-flex ml-2 align-items-center checkbox-wrapper">
                <input v-model="term" class="styled-checkbox checks2" id="styled-checkbox-1" type="checkbox" />
                <label for="styled-checkbox-1">Estoy de acuerdo con la política de privacidad</label>
              </div>
              <div class="mt-4 ml-2 d-flex align-items-center checkbox-wrapper">
                <input v-model="term2" class="styled-checkbox checks2" id="styled-checkbox-2" type="checkbox" />
                <label for="styled-checkbox-2">Estoy de acuerdo con los términos y condiciones</label>
              </div>
            </div>
            <div class="login-button__container w-100 mt-3">
              <button @click.prevent="$emit('registro')" class="btn primary" style="opacity:0.4">
                Iniciar sesión
              </button>
              <button @click.prevent="solicitaLogin" class="btn primary mt-3">
                Registro
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import api from '@/api/services'
  export default {
    data() {
      return {
        nombre: null,
        apellidos: null,
        mail: null,
        empresa: null,
        pass: null,
        pass2: null,
        tel: null,
        term: null,
        term2: null,
        showError: false,
        showSuccess: false,
        msgError: ''
      };
    },
    methods: {
      async solicitaLogin() {
        if (this.validacion() === true) {
          const details = {
            name: this.nombre,
            surname: this.apellidos,
            email: this.mail,
            telephone: this.tel,
            password: this.pass,
            company: this.empresa
          }
          const res = await api.register(details)
          if (res.message !== 'Invalid authorization') { this.muestraSuccess()
          } else {
            this.msgError = res.message
            this.muestraError()
          }
        } else { this.muestraError() }
      },

      muestraError () {
        this.showError = true
        const ambitoThis = this
        setTimeout(() => {
          var slideSource = document.getElementById('alertError');
          slideSource.classList.toggle('fade');
          ambitoThis.showError = false
        }, "2000")
      },

      muestraSuccess () {
        this.showSuccess = true
        setTimeout(() => {
          this.$emit('registro');
        }, "2000")
      },

      validacion () {
        let retorno = true
        const regMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!this.term || !this.term2) { retorno = 'Debes aceptar ambos términos y condiciones' }
        if (this.pass !== this.pass2) { retorno = 'Las contraseñas deben coincidir' }
        if (!regMail.test(this.mail)) { retorno = 'Debes introducir un email válido' }
        if (this.tel === null || this.tel.length !== 9) { retorno = 'Debes introducir un teléfono válido' }
        if (this.empresa === null) { retorno = 'Debes introducir un nombre de empresa válido' }
        if (this.apellidos === null) { retorno = 'Debes introducir apellidos válidos' }
        if (this.nombre === null) { retorno = 'Debes introducir un nombre válido' }
        this.msgError = retorno
        return retorno

      }
    },
  };
</script>
<style scoped>
.mb5x {
  margin-bottom: 5px !important;
}
</style>