<template>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0 gest-user">
          <base-dropdown
            menu-on-right
            class="nav-item"
            tag="li"
            title-tag="a"
            title-classes="nav-link pr-0"
          >
            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
              <b-media no-body class="align-items-center">
                <b-media-body class="ml-2 d-block d-lg-block">
                  <button class="mb-0 text-sm font-weight-bold btn btn-dropdown">Gestión de usuarios</button>
                </b-media-body>
              </b-media>
            </a>
            <template>
              <b-dropdown-header class="noti-title">
                <h6 class="text-overflow m-0">Administración de usuarios</h6>
              </b-dropdown-header>
              <div v-for="(link, index) in links" :key="index">
                <b-dropdown-item v-if="controlLink(link)" :href="link.link"> 
                    <span>{{ link.name }}</span>
                </b-dropdown-item>
              </div>
            </template>
          </base-dropdown>
        </b-navbar-nav>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
      ...mapGetters({
        controlUsuarios: 'controlUsuarios'
      })
  },
  data() {
    return {
        links: [
            { condicion: 'controlUsuarios', link: '#/controlUsuarios', name: 'Control usuarios'},
            { link: '#/cambiarcontra', name: 'Cambiar contraseña'},
            { link: '#/altaUsuario', name: 'Alta usuarios'},
            { link: '#/altaGestoria', name: 'Alta gestoría'}
        ]
    }
  },

  methods: {
    controlLink (condicion) {
        let retorno
        if (condicion.condicion?.toString()){
            if (condicion.condicion === 'controlUsuarios') {
                retorno = this.controlUsuarios
            }
        } else {
            retorno = true
        }
        return retorno
    }
  }
};
</script>
