<template slot="links">
<div>
    <div v-for="(route, index) in links" :key="index" :style="route.opa === '1' ? 'opacity: 0.5' : ''">
        <sidebar-item v-if="showLink(route.condition)" :link="route.link">
            <sidebar-item v-for="(subroute, index) in route.subLinks" :key="index" :link="subroute"></sidebar-item>
        </sidebar-item>
    </div>
</div>
</template>
<script>
  import { mapGetters } from 'vuex'
export default {
    computed: {
      ...mapGetters({
        facturacion: 'facturacion',
        promociones: 'promociones',
        gasto: 'gasto',
        encuestas: 'encuestas',
        gestoria: 'gestoria',
        dashboard: 'dashboard'
      })
    },

    data() {
      return {
        links: [{
          condition: 'dashboard',
          link: { name: 'Dashboards', icon: 'ni ico-sidebar ico_dashboard text-primary' },
          subLinks: [{ name: 'Dashboard', path: '/dashboard' }, { name: 'Detallado', path: '/alternative' }]
        },
        {
          condition: 'facturacion',
          link: { name: 'Facturación', icon: 'ico-sidebar ico_ticket text-primary' },
          subLinks: [{ name: 'Agregar ticket', path: '/agregarTicket' }, { name: 'Listado facturación', path: '/listadoTickets' }]
        },
        // {
        //   condition: 'redesSociales',
        //   link: { name: 'IoT Redes sociales', icon: 'ico-sidebar ico_ticket text-primary', path: '#4' }
        // },
        {
          condition: 'encuestas',
          link: { name: 'Encuestas', icon: 'ico-sidebar ico_encuestas' },
          subLinks: [{ name: 'Creación de encuestas', path: '/forms/encuestas' }, { name: 'Listado de encuestas', path: '/forms/elements' }, { name: 'Graficos de encuestas', path: '/dashboardEncuestas' }]
        },
        {
          condition: 'gestoria',
          link: { name: 'Gestoría', icon: 'ico-sidebar ico_encuestas' },
          subLinks: [{ name: 'Listado gastos', path: '/listadoFacturas?mod=list' }, { name: 'Listado facturación', path: '/listadoTickets?mod=list' }]
        },
        {
          condition: 'promociones',
          link: { name: 'Promociones (Próximamente)', icon: 'ico-sidebar ico_promociones', opa: '1', path: '#'}
          // subLinks: [{ name: 'Creación de promociones', path: '/forms/promociones' }, { name: 'Promociones clientes', path: '/promocionesCliente' }, { name: 'Listado de promociones', path: '/forms/listPromos' }]
        },
        {
          condition: 'gasto',
          link: { name: 'Gasto', icon: 'ico-sidebar ico_factura' },
          subLinks: [{ name: 'Agregar gasto', path: '/agregarFactura' }, { name: 'Listado gastos', path: '/listadoFacturas' }]
        },
        // {
        //   condition: 'iotcliente',
        //   link: {  name: 'Iot Cliente', icon: 'ico-sidebar ico_user', path: '/iotCliente' }
        // },
        {
          condition: 'comercio',
          link: { name: 'Comercio', icon: 'ico-sidebar ico_comercio', path: '#2' }
        }
        // {
        //   condition: 'gasto',
        //   link: { name: 'Bonos', icon: 'ico-sidebar ico_comercio'},
        //   subLinks: [{ name: 'Escanear Bono', path: '/scanBono' }, { name: 'Bonos cliente', path: '/bonosCliente' }, { name: 'Creación bono', path: '/forms/bonos' }]
        // }
        ]
      }
    },

    methods: {

      showLink (condition) {
        let retorno
        if (condition === 'dashboard') { retorno = this.dashboard }
        if (condition === 'facturacion') { retorno = this.facturacion }
        if (condition === 'redesSociales') { retorno = true }
        if (condition === 'encuestas') { retorno = this.encuestas }
        if (condition === 'gestoria') { retorno = this.gestoria }
        if (condition === 'promociones') { retorno = this.promociones }
        if (condition === 'gasto') { retorno = this.gasto }
        if (condition === 'iotcliente') { retorno = true }
        if (condition === 'comercio') { retorno = true }
        return retorno
      }
    },
}
</script>
