<template>
  <div class="form-body custom-login container-fluid p-0">
    <b-alert v-if="showError" show variant="danger" id="alertError"><a href="#" class="alert-link">{{ msgError }}</a></b-alert>
    <div class="row">
      <div class="col-md-6 col-sm-12 p-0 user-decoration">
        <h1>
          <a href=""><span class="logo"></span></a>
        </h1>
        <img src="../../../public/mobile-ticket.png" alt="" />
      </div>
      <div class="col-xl-6 col-lg-12 p-0 user-login">
        <div class="form-content">
          <div class="form-items">
            <div class="">
              <a href=""><span class="logo"></span></a>
              <h3>Conoce a tus cliente de manera personalizada.</h3>
              <h4>
                Accede a la plataforma que te ayuda analizar los habitos de
                compra de tu cliente.
              </h4>
            </div>
            <div v-if="!olvido">
              <form>
                <label for="username">Usuario o correo electrónico</label>
                <input v-model="user" id="user" type="text" name="username" placeholder="Usuario" required />
                <label for="pass">Contraseña</label>
                <input v-model="pass" type="password" name="pass" placeholder="Contraseña" required />
              </form>
            </div>
            <div v-else>
              <form>
                <label for="username">Correo electrónico</label>
                <input v-model="user" id="user" type="text" name="username" placeholder="Correo electrónico" required />
              </form>
            </div>
            <div v-if="!olvido" class="login-button__container w-100 mt-3">
              <button @click.prevent="solicitaLogin" class="btn primary">
                Acceder
              </button>
              <button @click.prevent="solicitaRegistro" class="btn primary mt-3" style="opacity:0.4">
                Registrarse
              </button>
            </div>
            <div v-else class="login-button__container w-100 mt-3">
              <button @click.prevent="" class="btn primary">
                Enviar solicitud
              </button>
              <button @click.prevent="olvido = false" class="btn primary mt-3" style="opacity:0.4">
                Volver
              </button>
            </div>
            <div v-if="!olvido" class="d-flex w-100 mt-3">
              <div class="w-50 d-flex align-items-center checkbox-wrapper">
                <input class="styled-checkbox checks2" id="styled-checkbox-1" type="checkbox" value="value1" />
                <label for="styled-checkbox-1">Recordar contraseña</label>
              </div>
              <div class="w-50 passremind__wrapper">
                <a href="#" @click="olvido = true">¿Has olvidado tu contraseña?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import api from '@/api/services'
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        user: null,
        pass: null,
        olvido: false,
        showError: false,
        msgError: ''
      };
    },

    methods: {
      ...mapActions({
        setAuthUser: 'setAuthUser',
        setFacturacion: 'setFacturacion',
        setPromociones: 'setPromociones',
        setGasto: 'setGasto',
        setEncuestas: 'setEncuestas',
        setControlUsuarios: 'setControlUsuarios',
        setDashboard: 'setDashboard',
        setGestoria: 'setGestoria'
      }),
      solicitaRegistro () {
        this.$emit('registro');
      },

      async solicitaLogin() {
         this.setFacturacion(true)
        this.setDashboard(true)
         this.setPromociones(true)
         this.setGasto(true)
         this.setEncuestas(true)
         this.setGestoria(true)
         this.setControlUsuarios(true)
         this.$emit('logueo')
         this.$router.push({ name: 'dashboard' })
      /*   if (this.registro) {
          this.registro = false
          return
        }
        var details = {
          username: this.user,
          password: this.pass,
        }
        const res = await api.login(details)
        if (res.message === 'Invalid authorization' || res.message === 'Username or password are incorrect') {
          this.msgError = res.message
          this.showError = true
          const ambitoThis = this
          setTimeout(() => {
            var slideSource = document.getElementById('alertError');
            slideSource.classList.toggle('fade');
            ambitoThis.showError = false
          }, "2000")
        } else {
          if (res.roles !== undefined) {
            res.roles.forEach((item) => {
              if (item.roleName.toLowerCase() === 'facturación') { this.setFacturacion(true) }
              if (item.roleName.toLowerCase() === 'dashboard') { this.setDashboard(true) }
              if (item.roleName.toLowerCase() === 'promociones') { this.setPromociones(true) }
              if (item.roleName.toLowerCase() === 'gastos') { this.setGasto(true) }
              if (item.roleName.toLowerCase() === 'encuestas') { this.setEncuestas(true) }
              if (item.roleName.toLowerCase() === 'gestoria') { this.setGestoria(true) }
              if (item.roleName.toLowerCase() === 'control de usuario') { this.setControlUsuarios(true) }
            })
          }
          this.setAuthUser(res)
          this.$emit('logueo')
          localStorage.setItem('usuario', this.user)
          this.$router.push({ name: 'dashboard' })
        } */
        // api.test()
      },
    },
  }
</script>
<style>
.mb5x {
  margin-bottom: 5px !important;
}

.alert {
  position:fixed;
  transition: opacity 1s; 
    top: 0px; 
    left: 0px; 
    width: 100%;
    z-index:9999; 
    border-radius:0px
}

.alert.fade {
  opacity: 0;
}
</style>